(function () {
  'use strict';

  /* @ngdoc object
   * @name dashboard.reports
   * @description
   *
   */
  angular
    .module('dashboard.results', [
      'ui.router'
    ]);
}());
